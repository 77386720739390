import React, { useRef } from "react";
import { isMobile } from "react-device-detect";

import TrackedButton from "components/common/analytics/TrackedButton";
import StandardButton from "components/common/buttons/ButtonStandard/ButtonStandard";
import {
  HomeDescriptionText,
  HomeDescriptionWrapper
} from "components/pages/home/HomeDescription/HomeDescription.styled";
import { registerScrollAnimation, useScrollContext } from "contexts/ScrollContext";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";
import Translate from "utils/translation/translation";

export const HomeDescription = () => {
  const descriptionText = useRef<HTMLHeadingElement>(null);
  const descriptionButton = useRef<HTMLDivElement>(null);
  const [, dispatch] = useScrollContext();

  useFirstRenderEffect(() => {
    dispatch(
      registerScrollAnimation({
        target: descriptionText.current as HTMLElement,
        animation: { opacity: 0, duration: 1, delay: isMobile ? 2.25 : 0 },
        triggerOffsetMultiplier: -0.75,
        proxyElement: descriptionText.current,
        instantPlayback: isMobile
      })
    );
    dispatch(
      registerScrollAnimation({
        target: descriptionButton.current as HTMLElement,
        animation: { opacity: 0, duration: 1 },
        triggerOffsetMultiplier: -0.75,
        proxyElement: descriptionText.current,
        instantPlayback: false
      })
    );
  });

  return (
    <HomeDescriptionWrapper>
      <HomeDescriptionText ref={descriptionText}>
        {Translate("Common_Short_Team_Description")}
      </HomeDescriptionText>
      <TrackedButton category="navigation" action="home-about">
        <StandardButton
          buttonRef={descriptionButton}
          url={"/about/"}
          text="Home_Link_AbDreamStorm"
          variant="home-description-button"
          data-scroll
        />
      </TrackedButton>
    </HomeDescriptionWrapper>
  );
};

export default HomeDescription;
