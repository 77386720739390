import { GatsbyImage } from "gatsby-plugin-image";
import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import { CommonDescriptionFont, StandardFont } from "styled/common/font-styles/CommonFonts";
import { Background, NonSelectable } from "styled/common/Utils";
import styled from "styled-components";

const WhyUsHeight = 360;
const WhyUsRows = 18;
const WhyUsRowSize = WhyUsHeight / WhyUsRows;

export const HomeWhyUsWrapper = styled.section`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-template-rows: repeat(${WhyUsRows}, 1fr);
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-template-rows: repeat(${WhyUsRows}, 1fr);
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-template-rows: repeat(28, 1fr);
  }
  ${CalculateFromHeight("height", { mobile: 630, tablet: 260, desktop: WhyUsHeight })}
  ${CalculateFromHeight("margin-bottom", { mobile: 15, desktop: 20 })}
  ${CalculateFromHeight("margin-top", { tablet: -3 })}
  grid-template-columns: repeat(12, 1fr);
  width: 100%;
  display: grid;
  position: relative;
`;

export const WhyUsShortTeamDescription = styled.h3`
  ${CommonDescriptionFont}
`;
export const WhyUsLongTeamDescription = styled.h3`
  ${StandardFont}
`;

export const WhyUsLeftCloudProxy = styled.div`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    position: absolute;
    ${Background}
  }
  ${CalculateFromWidth("width", { desktop: 5 })}
  ${CalculateFromWidth("height", { desktop: 120 })}
  ${CalculateFromHeight("top", { desktop: -10 })}
  ${({ theme: { breakpoints } }) => breakpoints.tabletMobile} {
    display: none;
  }
`;

export const WhyUsSummaryProxy = styled.div`
  position: absolute;
  ${Background}
  ${CalculateFromHeight("height", { mobile: 2, tablet: 2, desktop: 50 })}
  ${CalculateFromHeight("top", {
    mobile: WhyUsRowSize * 14,
    tablet: WhyUsRowSize * 14,
    desktop: WhyUsRowSize * 12
  })}
  ${CalculateFromWidth("width", { mobile: 5, tablet: 5, desktop: 5 })}
`;

export const WhyUsSideTextParagraph = styled.p`
  ${StandardFont}
  ${CalculateFromHeight("margin-bottom", { mobile: 5, tablet: 5 })}
`;

export const WhyUsRecommendationIntro = styled.h3`
  ${CommonDescriptionFont}
`;

export const WhyUsImage = styled(GatsbyImage)`
  ${NonSelectable}
`;
