import React from "react";

import TrackedButton from "components/common/analytics/TrackedButton";
import CtaButton from "components/common/buttons/ButtonCta/ButtonCta";
import PageTitle, { type PageTitleProps } from "components/common/other/PageTitle/PageTitle";
import Recommendations from "components/common/recommendations/Recommendations/Recommendations";
import Newsletter from "components/common/sections/Newsletter/Newsletter";
import type { Service, ServiceProps } from "components/common/sections/Services/Service";
import Services from "components/common/sections/Services/Services";
import { ServicesButton } from "components/common/sections/Services/Services.styled";
import FloatingImages from "components/images/ImagesFloating/ImagesFloating";
import Layout from "components/layout/Layout/Layout";
import HomeDescription from "components/pages/home/HomeDescription/HomeDescription";
import HomePartners from "components/pages/home/HomePartners/HomePartners";
import HomeProjects from "components/pages/home/HomeProjects/HomeProjects";
import HomeWhyUs from "components/pages/home/HomeWhyUs/HomeWhyUs";
import MainCloud from "components/rendering/MainCloud/MainCloud";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Circ } from "gsap";
import { useTheme } from "styled-components";
import { GetSeoData } from "utils/seo-utils";
import Translate from "utils/translation/translation";

export interface PageProps {
  location: Location;
}

const Home = ({ location }: PageProps) => {
  const {
    colors: { games, ar, turquoise }
  } = useTheme();

  const servicesSecondRow: ServiceProps[] = [
    {
      number: 4,
      color: ar,
      scrollSpeed: -3,
      ...defaultFourthService
    },
    {
      number: 5,
      color: games,
      scrollSpeed: -4,
      ...defaultFifthService
    },
    {
      number: 6,
      color: turquoise,
      scrollSpeed: -4.51,
      ...defaultSixthService
    }
  ];

  return (
    <>
      <GatsbySeo {...GetSeoData("Home")} />
      <Layout location={location}>
        <MainCloud color="VioletBlack" variant="MainCloudWrapper" scrollSpeed={-7.5} />
        <PageTitle {...pageTitleData} />
        <HomeDescription />
        <FloatingImages
          leftImage={"Floating1"}
          middleImage={"Floating2"}
          rightImage={"Floating3"}
        />
        <Services inAbout={false} secondRow={servicesSecondRow} tabletLayout>
          <ServicesButton secondRow>
            <TrackedButton category="form" action="home-form-open">
              <CtaButton />
            </TrackedButton>
          </ServicesButton>
        </Services>
        <HomeProjects />
        <HomeWhyUs />
        <HomePartners />
        <Recommendations />
        <Newsletter cloudVariant="NewsletterCloudContact" />
      </Layout>
    </>
  );
};

export default Home;

const pageTitleData: PageTitleProps = {
  instantPlayback: true,
  variant: "home-title",
  isTitle: true,
  headers: [
    {
      text: "Home_Title_We",
      variant: "HomeWe",
      id: "home-we",
      animation: { x: -100, opacity: 0, duration: 1, delay: 0.75, ease: Circ.easeOut },
      scrollSpeed: 1,
      horizontalScrollSpeed: 1
    },
    {
      text: "Home_Title_Are",
      variant: "HomeAre",
      id: "home-are",
      animation: { x: 100, opacity: 0, duration: 1, delay: 1, ease: Circ.easeOut },
      scrollSpeed: 0.1,
      horizontalScrollSpeed: -0.5
    },
    {
      text: "Home_Title_Dream",
      variant: "HomeDream",
      id: "home-dream",
      animation: { x: -50, opacity: 0, duration: 1.5, delay: 1.25, ease: Circ.easeOut },
      scrollSpeed: -1,
      horizontalScrollSpeed: 1
    },
    {
      text: "Home_Title_Storm",
      variant: "HomeStorm",
      id: "home-storm",
      animation: { x: 100, opacity: 0, duration: 1, delay: 1.5, ease: Circ.easeOut },
      scrollSpeed: -1,
      horizontalScrollSpeed: -0.4
    }
  ],
  subtitle: {
    text: "Home_Motto",
    variant: "home-subtitle"
  },
  showScrollIcon: "true",
  scrollIconVariant: "home-scroll-icon-wrapper"
};

const defaultFourthService: Service = {
  title: Translate("Common_Header_Automation"),
  description: Translate("Common_Text_Automation"),
  iconName: "Automation1Icon"
};

const defaultFifthService: Service = {
  title: Translate("Common_Header_Outsourcing"),
  description: Translate("Common_Text_Outsourcing"),
  iconName: "AtmosphereIcon"
};

const defaultSixthService: Service = {
  title: Translate("Common_Header_Design"),
  description: Translate("Common_Text_Design"),
  iconName: "PassionIcon"
};
