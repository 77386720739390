import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import { CenterFlex, NonSelectable } from "styled/common/Utils";
import styled, { css } from "styled-components";

export const HomePartnersContent = styled.section`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
  ${CalculateFromHeight("margin-bottom", { mobile: 20, desktop: 20 })}
  ${CalculateFromHeight("margin-top", { tablet: 20 })}

  width: 100%;
`;

export const PartnersIconWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  flex-shrink: auto;
  ${CalculateFromHeight("height", { mobile: 30, tablet: 30, desktop: 10 })}
  ${CalculateFromHeight("padding-top", { desktop: 5 })}
  ${CalculateFromWidth("padding-left", { mobile: 3, tablet: 3 })}
  ${CalculateFromWidth("padding-right", { mobile: 3, tablet: 3 })}
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 2 / span 23;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 2 / span 23;
  }
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 2 / span 22;
    ${CenterFlex}
    flex-direction: row;
    justify-content: space-between;
  }
  justify-content: space-between;
`;

const PartnerIconCenter = css`
  margin-left: auto !important;
  margin-right: auto !important;
`;

export const PartnerIcon = styled.img<{ center: boolean }>`
  ${CalculateFromWidth("margin-left", { mobile: 2, tablet: 2 })}
  ${CalculateFromWidth("margin-right", { mobile: 2, tablet: 2 })}
  ${CalculateFromWidth("margin-bottom", { mobile: 2, tablet: 2 })}
  ${CalculateFromWidth("margin-top", { mobile: 2, tablet: 2 })}
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    max-width: 28%;
    max-height: 30%;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    max-width: 33%;
    max-height: 20%;
  }
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    max-width: 15%;
    height: 40%;
    display: inline-block;
  }

  vertical-align: middle;
  ${NonSelectable}
  ${({ center }) => center && PartnerIconCenter}
`;
