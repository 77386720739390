import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import { CommonDescriptionFont } from "styled/common/font-styles/CommonFonts";
import styled from "styled-components";

export const HomeDescriptionWrapper = styled.section`
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    width: 100%;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    width: 100%;
  }
  ${CalculateFromHeight("height", { mobile: 160, tablet: 40, desktop: 50 })}
  ${CalculateFromHeight("margin-top", { mobile: 5, tablet: 15, desktop: 11 })}
  ${CalculateFromHeight("margin-bottom", { mobile: 10, tablet: 35, desktop: 40 })}
  ${CalculateFromWidth("margin-right", { desktop: -3 })}

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
`;

export const HomeDescriptionText = styled.h3`
  ${CommonDescriptionFont}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 2 / span 9;
    grid-row: 1 / span 10;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 2 / span 10;
    grid-row: 1 / span 8;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 2 / span 10;
    grid-row: 1 / span 8;
  }
`;
