import * as React from "react";

import HeaderGroup, {
  type HeaderGroupProps
} from "components/common/headers/HeaderGroup/HeaderGroup";
import { HomeWhyUsWrapper } from "components/pages/home/HomeWhyUs/HomeWhyUs.styled";
import HomeWhyUsContent from "components/pages/home/HomeWhyUs/HomeWhyUsContent";
import { Circ } from "gsap";

export const HomeWhyUs = () => {
  const headerData: HeaderGroupProps = {
    instantPlayback: false,
    animationPlaybackOffset: -0.75,
    headers: [
      {
        text: "Home_Header_Why",
        variant: "HomeWhyUsWhy",
        id: "home-why-us-why",
        animation: { x: "20%", opacity: 0, duration: 0.75, delay: 0, ease: Circ.easeOut },
        horizontalScrollSpeed: -0.5
      },
      {
        text: "Home_Header_Our",
        variant: "HomeWhyUsOur",
        id: "home-why-us-our",
        animation: { x: "-20%", opacity: 0, duration: 0.75, delay: 0.125, ease: Circ.easeOut },
        horizontalScrollSpeed: -0.75
      },
      {
        text: "Home_Header_Team",
        variant: "HomeWhyUsTeam",
        id: "home-why-us-team",
        animation: { x: "20%", opacity: 0, duration: 0.75, delay: 0.25, ease: Circ.easeOut },
        horizontalScrollSpeed: -1
      }
    ]
  };

  return (
    <HomeWhyUsWrapper>
      <HeaderGroup {...headerData} />
      <HomeWhyUsContent />
    </HomeWhyUsWrapper>
  );
};

export default HomeWhyUs;
