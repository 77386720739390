import React, { useRef } from "react";

import TrackedButton from "components/common/analytics/TrackedButton";
import StandardButton from "components/common/buttons/ButtonStandard/ButtonStandard";
import LocomotiveElement from "components/locomotive/LocomotiveElement/LocomotiveElement";
import {
  WhyUsImage,
  WhyUsLeftCloudProxy,
  WhyUsLongTeamDescription,
  WhyUsRecommendationIntro,
  WhyUsShortTeamDescription,
  WhyUsSideTextParagraph,
  WhyUsSummaryProxy
} from "components/pages/home/HomeWhyUs/HomeWhyUs.styled";
import RendererWrapper from "components/rendering/RendererWrapper/RendererWrapper";
import { useImageDatabaseContext } from "contexts/ImageContext";
import { addCloud, useRenderContext } from "contexts/RenderContext";
import { registerScrollAnimation, useScrollContext } from "contexts/ScrollContext";
import { Circ } from "gsap";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";
import Translate from "utils/translation/translation";

export const HomeWhyUsContent = () => {
  const leftCloudWrapper = useRef<HTMLDivElement>(null);
  const rightCloudWrapper = useRef<HTMLDivElement>(null);
  const [imageDatabase] = useImageDatabaseContext();
  const sideText = useRef<HTMLElement>(null);
  const description = useRef<HTMLElement>(null);
  const summary = useRef<HTMLElement>(null);
  const [, dispatch] = useScrollContext();
  const [, dispatchRender] = useRenderContext();
  const cloudLeft = {
    name: "VioletBlue",
    wrapper: leftCloudWrapper.current,
    group: "Why-Us-Left"
  };
  const cloudRight = {
    name: "PinkViolet",
    wrapper: rightCloudWrapper.current,
    group: "Why-Us-Right"
  };

  useFirstRenderEffect(() => {
    dispatchRender(addCloud(cloudLeft));
    dispatchRender(addCloud(cloudRight));
    sideText.current &&
      dispatch(
        registerScrollAnimation({
          target: sideText.current,
          animation: { opacity: 0, duration: 1.75, ease: Circ.easeOut },
          triggerOffsetMultiplier: -0.25
        })
      );
    description.current &&
      dispatch(
        registerScrollAnimation({
          target: description.current,
          animation: { opacity: 0, duration: 1.75, ease: Circ.easeOut },
          triggerOffsetMultiplier: -0.5
        })
      );
    summary.current &&
      dispatch(
        registerScrollAnimation({
          target: summary.current,
          animation: { opacity: 0, duration: 1.75, ease: Circ.easeOut },
          triggerOffsetMultiplier: -0.75
        })
      );
  }, [sideText.current, description.current, summary.current]);

  return (
    <>
      <LocomotiveElement
        variant="WhyUsDescription"
        scrollSpeed={2.5}
        scrollDelay={0.5}
        elementRef={description}
      >
        <WhyUsShortTeamDescription>
          {Translate("Home_Short_Team_Description")}
        </WhyUsShortTeamDescription>
      </LocomotiveElement>
      <WhyUsLeftCloudProxy id="why-us-left-cloud-proxy" />
      <LocomotiveElement
        variant="WhyUsLeftCloud"
        scrollSpeed={-5}
        scrollTarget="#why-us-left-cloud-proxy"
      >
        <RendererWrapper
          elementRef={leftCloudWrapper}
          variant="fill-background-space"
          type="cloud"
          additionalData={"VioletBlue"}
        />
      </LocomotiveElement>
      <LocomotiveElement variant="WhyUsLeftPhoto" scrollSpeed={-0.25}>
        <WhyUsImage
          image={imageDatabase.HomeLeft.childImageSharp.gatsbyImageData}
          draggable={false}
          alt="Left photo"
        />
      </LocomotiveElement>
      <LocomotiveElement variant="WhyUsRightCloud" scrollSpeed={-0.5}>
        <RendererWrapper
          elementRef={rightCloudWrapper}
          variant="fill-background-space"
          type="cloud"
          additionalData={"PinkViolet"}
        />
      </LocomotiveElement>
      <LocomotiveElement variant="WhyUsRightPhoto" scrollSpeed={3}>
        <WhyUsImage
          image={imageDatabase.HomeRight.childImageSharp.gatsbyImageData}
          draggable={false}
          alt="Right photo"
        />
      </LocomotiveElement>
      <LocomotiveElement variant="WhyUsSideText" scrollSpeed={-0.5} elementRef={sideText}>
        <WhyUsSideTextParagraph>{Translate("Home_Long_Team_Description1")}</WhyUsSideTextParagraph>
        <WhyUsLongTeamDescription>
          {Translate("Home_Long_Team_Description2")}
        </WhyUsLongTeamDescription>
        <TrackedButton category="navigation" action="home-open-team">
          <StandardButton
            variant="why-us-side-button"
            text="Home_Link_DiscoverOurTeam"
            url={"/about/"}
          />
        </TrackedButton>
      </LocomotiveElement>
      <WhyUsSummaryProxy id="why-us-summary-proxy" />
      <LocomotiveElement
        variant="WhyUsSummary"
        scrollSpeed={-0.5}
        scrollTarget={"#why-us-summary-proxy"}
        elementRef={summary}
      >
        <WhyUsRecommendationIntro>
          {Translate("Home_Recommendation_Intro")}
        </WhyUsRecommendationIntro>
      </LocomotiveElement>
    </>
  );
};

export default HomeWhyUsContent;
