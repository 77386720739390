import React, { useEffect, useRef, useState } from "react";

import useSize from "@react-hook/size";
import { registerScrollAnimation, useScrollContext } from "contexts/ScrollContext";
import { Circ } from "gsap";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";
import Aloki from "media/images/partners/aloki.svg";
import Amazon from "media/images/partners/amazon.svg";
import ChaosTheory from "media/images/partners/chaos-theory.svg";
import FutureProcessing from "media/images/partners/future-processing.svg";
import GamesOperators from "media/images/partners/games-operators.svg";
import ItFocus from "media/images/partners/it-focus.svg";
import Katana from "media/images/partners/katana.svg";
import M1 from "media/images/partners/m1.svg";
import Mastercard from "media/images/partners/mastercard.svg";
import Microsoft from "media/images/partners/microsoft.svg";
import nVidia from "media/images/partners/nvidia.svg";
import PixelMakers from "media/images/partners/pixelmakers.svg";
import Plaza from "media/images/partners/plaza.svg";
import TheLabz from "media/images/partners/the-labz.svg";
import Triheadz from "media/images/partners/triheadz.svg";
import Widlarz from "media/images/partners/widlarz.svg";
import Wsti from "media/images/partners/wsti.svg";

import { HomePartnersContent, PartnerIcon, PartnersIconWrapper } from "./HomePartners.styled";

export const HomePartners = () => {
  const wrapper = useRef<HTMLElement>(null);
  const [, dispatch] = useScrollContext();
  const [partnersPerRow, setPartnersPerRow] = useState(6);
  const [isDesktop, setIsDesktop] = useState(true);
  const windowSize = useSize(wrapper);

  useFirstRenderEffect(() => {
    partners.forEach((element, index) => {
      dispatch(
        registerScrollAnimation({
          target: element.name,
          animation: {
            opacity: 0,
            delay: 0.07 * index,
            y: 100,
            duration: 1.25,
            ease: Circ.easeOut
          },
          triggerOffsetMultiplier: -0.25,
          proxyElement: wrapper.current
        })
      );
    });
  });

  useEffect(() => {
    const partnersPerRow = window.innerWidth < 1024 ? 3 : 6;

    if (partnersPerRow === 6) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }

    setPartnersPerRow(partnersPerRow);
  }, [windowSize]);

  const RenderPartners = () => {
    const result = [] as JSX.Element[];

    for (let i = 0; i < Math.floor(partners.length / partnersPerRow) + 1; i++) {
      result.push(
        <PartnersIconWrapper key={i}>
          {partners
            .slice(
              i * partnersPerRow + (isDesktop ? (i === 1 || i === 2 ? -1 : 0) : 0),
              (i + 1) * partnersPerRow + (isDesktop ? -1 : 0)
            )
            .map(({ name, image }) => {
              return (
                <PartnerIcon
                  loading="eager"
                  key={name}
                  src={image}
                  alt={name}
                  id={name}
                  center={!isDesktop && partners.length - partnersPerRow * i < partnersPerRow}
                />
              );
            })}
        </PartnersIconWrapper>
      );
    }

    return result;
  };

  return <HomePartnersContent ref={wrapper}>{RenderPartners()}</HomePartnersContent>;
};

export default HomePartners;

const partners = [
  {
    name: "nVidia",
    image: nVidia
  },
  {
    name: "Future Processing",
    image: FutureProcessing
  },
  {
    name: "Plaza",
    image: Plaza
  },
  {
    name: "Katana Studio",
    image: Katana
  },
  {
    name: "Triheadz",
    image: Triheadz
  },
  {
    name: "Mastercard",
    image: Mastercard
  },
  {
    name: "GamesOperators",
    image: GamesOperators
  },
  {
    name: "It.Focus",
    image: ItFocus
  },
  {
    name: "M1",
    image: M1
  },
  {
    name: "PixelMakers",
    image: PixelMakers
  },
  {
    name: "The Labz",
    image: TheLabz
  },
  {
    name: "Chaos Theory Games",
    image: ChaosTheory
  },
  {
    name: "Aloki",
    image: Aloki
  },
  {
    name: "Microsoft",
    image: Microsoft
  },
  {
    name: "Amazon",
    image: Amazon
  },
  {
    name: "Wyższa Szkoła Technologii Informatycznych w Katowicach",
    image: Wsti
  },
  {
    name: "Widlarz",
    image: Widlarz
  }
];
